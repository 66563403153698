import {ref, watch, computed} from '@vue/composition-api'
import {title, formatDate, formatDateToMonthShort} from '@core/utils/filter'

import useHttpService from '@/http/useHttpService'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useTableHelper from '@/helpers/useTableHelper'

const {users} = useHttpService()

export default function useUsersList(isReported, isBlocked) {
    // Use toast
    const toast = useToast()

    // Table Handlers
    const tableColumns = [
        {
            key: 'user',
            sortable: false
        },
        // { key: 'email', sortable: false },
        {
            key: 'type',
            sortable: false
        },
        {
            key: 'countryOfResidence',
            label: 'Country',
            sortable: false,
        },
        {
            key: 'userCreatedType',
            label: 'Account type',
            sortable: false
        },
        {key: 'views'},
        {
            key: 'fieldOfWorks',
            label: 'Industry',
            sortable: false,
        },
        {
            key: 'created',
            label: 'Created',
            sortable: false,
        },
        {
            key: 'lastLogin',
            label: 'Last login',
            sortable: false,
        },
        {
            key: 'isActive',
            label: 'Active',
            sortable: false,
        },
        {
            key: 'isProfileFullyFilled',
            label: 'Completed',
            sortable: false
        },
        {
            key: 'completedPercent',
            label: '% of completion',
            sortable: false
        },
        {
            key: 'isVisible',
            label: 'Visible',
            sortable: false,
        },
        {
            key: 'isPremium',
            label: 'Premium',
            sortable: false,
        },
        {
            key: 'isVerifiedRecruiter',
            label: 'Company verified',
            sortable: false,
        },
        // {
        //   key: 'subscriptionExpiresAt',
        //   label: 'Subscription Exp',
        // },
        {key: 'actions'},
    ]

    const affiliateTable = [
        {
            key: 'user',
            sortable: false
        },
        {
            key: 'accountsNumber',
            sortable: false
        },
        {
            key: 'createdAccounts',
            sortable: false
        },
        {
            key: 'verifiedAccounts',
            sortable: false
        },
        {
            key: 'confirmedAccounts',
            sortable: false
        },
        {
            key: 'invalidAccounts',
            sortable: false
        },
    ]

    if (isReported) {
        tableColumns.splice(1, 0,
            {
                key: 'reportCount',
                label: 'Num of Reports',
                sortable: false,
            });
    }

    if (isBlocked) {
        tableColumns.splice(1, 0,
            {
                key: 'blockCount',
                label: 'Num of Blocks',
                sortable: false,
            });
    }

    const {
        refTable,
        perPage,
        totalCount,
        currentPage,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        dataMeta,
        refetchData
    } = useTableHelper()

    const industryFilter = ref(null)
    const statusFilter = ref(null)
    const typeFilter = ref(null)
    const companyType = ref(null)
    const createdFilter = ref(null)
    const countryFilter = ref(null)
    const isIncompleteFilter = ref(null)
    const inactiveFilter = ref(null)
    const isPremium = ref(null)
    const IsNotVerified = ref(null)
    const viewsRange = ref(null)


    const advanceFilters = computed(() => {
        const [userCreatedFrom, userCreatedUntil] = createdFilter.value !== null ? createdFilter.value.split(' to ') : []
        const [viewsFromDate, viewsToDate] = viewsRange.value !== null ? viewsRange.value.split(' to ') : []

        return {
            isVisible: statusFilter.value,
            type: typeFilter.value,
            recruiterType: companyType.value,
            fieldOfWorks: industryFilter.value,
            countryOfResidences: countryFilter.value,
            userCreatedFrom,
            userCreatedUntil,
            viewsFromDate,
            viewsToDate,
            IsIncompliteProfile: isIncompleteFilter.value === false ? null : isIncompleteFilter.value,
            isInactive: inactiveFilter.value ?? null,
            isPremium: isPremium.value ? true : null,
            IsNotVerified: IsNotVerified.value,
            isReported: isReported ? true : null,
            isBlocked: isBlocked ? true : null
        }
    })

    watch([currentPage, perPage], () => {
        refetchData()
    })

    watch([searchQuery, createdFilter, statusFilter, industryFilter, typeFilter, countryFilter, isIncompleteFilter, inactiveFilter, companyType, isPremium, IsNotVerified, viewsRange], () => {
        currentPage.value = 1
        refetchData()
    })

    const fetchUsers = (ctx, callback) => {
        users.getUsers(currentPage.value, perPage.value, searchQuery.value, advanceFilters.value)
            .then((response) => {
                totalCount.value = response.totalCount
                callback(response.results)
            })
            .catch((e) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching users list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    const fetchAffiliateUsers = (ctx, callback) => {
        users.getAffiliateUsers(currentPage.value, perPage.value, searchQuery.value)
            .then((response) => {
                totalCount.value = response.totalCount
                callback(response.results)
            })
            .catch((e) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching users list',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    function exportUsers() {
        users.exportUsersToExel(currentPage.value, perPage.value, searchQuery.value, advanceFilters.value)
            .then(response => {
                let blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
                let url = window.URL.createObjectURL(blob)
                window.location.href = url
            })
    }

    function toggleUserVisibility(uid) {
        users.toggleProfileVisibility(uid)
            .then(() => {
                refetchData()
            })
    }

    function deleteUser(uid) {
        users.deleteProfile(uid)
            .then(() => {
                refetchData()
            })
    }

    function blockUser(uid) {
        users.changeUserBlockStatus(uid)
            .then(() => {
                refetchData()
            })
    }

    function togglePremium(uid) {
        users.toggleUserPremiumStatus(uid)
            .then(() => {
                refetchData()
            })
    }

    function toggleCompanyVerified(uid) {
        users.toggleCompanyVerified(uid).then(() => refetchData())
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = role => {
        if (role === 'Crew') return 'success'
        if (role === 'Recruiter') return 'warning'
        return 'primary'
    }

    const resolveUserRoleIcon = role => {
        if (role === 'subscriber') return 'UserIcon'
        if (role === 'author') return 'SettingsIcon'
        if (role === 'maintainer') return 'DatabaseIcon'
        if (role === 'editor') return 'Edit2Icon'
        if (role === 'admin') return 'ServerIcon'
        return 'UserIcon'
    }

    const resolveUserStatusVariant = status => {
        if (status === 'MANUAL') return 'primary'
        if (status === 'GOOGLE') return 'info'
        return 'primary'
    }

    const resolveUserLink = (role, uid) => {
        const url = process.env['VUE_APP_REDIRECT_LINK']
        if (role !== 'Undefined') {
            return `${url}/${role}s/${uid}`
        }
    }

    const resolveProfileCompletedIcon = value => {
        if (value) {
            return 'CheckIcon'
        } else {
            return 'XIcon'
        }
    }

    const resolveUserRole = user => {
        return user.type === 'Crew' ? 'Crew' : resolveRecruiterType(user.recruiterType)
    }

    const resolveRecruiterType = value => {
        switch (value) {
            case 'RECRUITER':
                return 'Recruiter'
            case 'COMPANY_REPRESENT':
                return 'Company'
            case 'INDUSTRY_PROFESSIONAL':
                return 'Industry professional'
            default:
                return 'Recruiter'
        }
    }

    return {
        fetchUsers,
        fetchAffiliateUsers,
        tableColumns,
        affiliateTable,
        perPage,
        currentPage,
        totalCount,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refTable,
        isIncompleteFilter,
        inactiveFilter,
        IsNotVerified,

        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        refetchData,
        resolveUserLink,
        resolveProfileCompletedIcon,
        toggleUserVisibility,
        deleteUser,
        exportUsers,
        blockUser,
        resolveUserRole,
        togglePremium,
        toggleCompanyVerified,

        // Extra Filters
        statusFilter,
        industryFilter,
        typeFilter,
        createdFilter,
        countryFilter,
        companyType,
        isPremium,
        viewsRange
    }
}
