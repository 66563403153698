import { ref } from '@vue/composition-api'
import useHttpService from '@/http/useHttpService'

const {users} = useHttpService()

export default function useUserFilters() {

  const statusOptions = [
    {label: 'Any', value: null},
    {label: 'Visible', value: true},
    {label: 'Hidden', value: false},
  ]

  const typeOptions = [
    {label: 'Any', value: null},
    {label: 'Crew', value: 'Crew'},
    {label: 'Recruiter', value: 'Recruiter'},
  ]

  const companyTypeOptions = [
    {label: 'Recruiter', value: 'RECRUITER'},
    {label: 'Company', value: 'COMPANY_REPRESENT'},
    {label: 'Industry professional', value: 'INDUSTRY_PROFESSIONAL'},
  ]

  const verifiedOptions = [
    {label: 'All', value: null},
    {label: 'Verified', value: false},
    {label: 'Not verified', value: true},
  ]

  const industryOptions = ref([]);
  const countryOptions = ref([]);

  users.getUsersFilters()
    .then(response => {
      industryOptions.value = response.fieldOfWorks;
      countryOptions.value = response.countries;
    })


  return {
    statusOptions,
    typeOptions,
    industryOptions,
    countryOptions,
    companyTypeOptions,
    verifiedOptions
  }
}
